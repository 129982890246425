import React, { useEffect, useState } from 'react';
import { Link, Route, Routes, useNavigate, Redirect } from 'react-router-dom';
import './paginainicial.css';
import { FaTimes, FaBars, FaHome, FaChartBar, FaFilePdf, FaUserPlus, FaBookOpen, FaUsers, FaAddressBook, FaCertificate, FaClipboard, FaUserEdit, FaPlus, FaEdit } from 'react-icons/fa';
import { LuFileEdit } from "react-icons/lu";
import { MdLibraryBooks } from 'react-icons/md';
import axios from 'axios';
import { API_BASE_URL } from './pages/config';
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Table,
  Spinner,
  Pagination,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";


import Agenda from './pages/Agenda';

// Importações dos componentes de páginas
import Financeiro from './pages/Financeiro';
import CadastroEscola from './pages/CadastroEscola';
import CadastroUsuarios from './pages/CadastroUsuarios';
import Cursos from './pages/CadastroCursos';
import Turmas from './pages/CadastroTurma';
import Chamada from './pages/SaladeAula';
import Certificados from './pages/Certificados';
import Avaliacao from './pages/Avaliacao';
import Matricula from './pages/CadastroMatricula';
import Home from './pages/Home';
import Material from './pages/Treinamento';
import Teste from './pages/Testes';
import MaterialExtra from './pages/MaterialExtra';
import Pagina404 from './pages/404'; 

function Quadrado({ icone, texto, to, className }) {
  let Icone;

  switch (icone) {
    case 'homeIc':
      Icone = FaHome;
      break;
    case 'chart':
      Icone = FaChartBar;
      break;
    case 'file':
      Icone = FaFilePdf;
      break;
    case 'user-plus':
      Icone = FaUserPlus;
      break;
    case 'book-open':
      Icone = FaBookOpen;
      break;
    case 'users':
      Icone = FaUsers;
      break;
    case 'address-book':
      Icone = FaAddressBook;
      break;
    case 'certificate':
      Icone = FaCertificate;
      break;
    case 'clipboard':
      Icone = FaClipboard;
      break;
    case 'matricula':
      Icone = FaUserEdit;
      break;
    case 'material':
      Icone = MdLibraryBooks;
      break;
    default:
      Icone = FaChartBar;
  }


  return (
    <Link to={to} className={className} >
      <div>
        <div className="retangulo ">
          <div className="conteudo">
            <div className="icone">
              <Icone />
            </div>
            <div className="texto">
              <span>{texto}</span>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}

function PaginaInicial() {
  const [userType, setUserType] = useState(0); // Valor padrão 0 para não autenticado ou tipo de usuário desconhecido
  const [userName, setUserName] = useState(''); // Nome do usuário
  const [userProfilePhoto, setUserProfilePhoto] = useState('');
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
  const defaultProfilePhoto = `${API_BASE_URL}/FotoPerfil/default.png`;
  const userProfilePhotoUrl = userProfilePhoto ? `${API_BASE_URL}${userProfilePhoto}` : defaultProfilePhoto;
  const handleMenuToggle = () => {
    // const coluna30 = document.querySelector('.coluna-30');
    setMenuOpen(!menuOpen);
    // coluna30.classList.toggle('menu-aberto');
  };

  const handleLogout = () => {
    // Limpar o localStorage ao clicar em "Sair"
    localStorage.removeItem('userName');
    localStorage.removeItem('userType');
    localStorage.removeItem('userId');
    localStorage.removeItem('userProfilePhoto')
    localStorage.removeItem('schoolId')
    localStorage.removeItem('turmaID')

    // Redirecionar para a página inicial ("/")
    navigate('/');
  };

  // Manipulador de evento para o clique no ícone de lápis
  const handleEditProfilePhoto = () => {
    // Abrir o seletor de arquivo
    document.getElementById('fileInput').click();
  };

  // Manipulador de evento para o upload da nova foto de perfil
  const handleFileInputChange = async (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('cp_foto_perfil', file);
    formData.append('userId', localStorage.getItem('userId')); // Passando o ID do usuário

    try {
      const response = await axios.post(`${API_BASE_URL}/uploadProfilePhoto`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      setUserProfilePhoto(response.data.filePath);
    } catch (error) {
      console.error('Erro ao fazer upload da foto de perfil:', error);
    }
  };

  useEffect(() => {
    // Obtenha o tipo de usuário armazenado após o login
    const storedUserType = localStorage.getItem('userType');
    if (storedUserType) {
      setUserType(parseInt(storedUserType)); // Defina o tipo de usuário recuperado do localStorage
    }
    const storedUserName = localStorage.getItem('userName');
    if (storedUserName) {
      setUserName(storedUserName); // Defina o nome do usuário recuperado do localStorage
    }
    const storedUserProfilePhoto = localStorage.getItem('userProfilePhoto');
    if (storedUserProfilePhoto) {
      setUserProfilePhoto(storedUserProfilePhoto); // Define o endereço da foto de perfil recuperado do localStorage
    }
  }, []);

  const renderQuadrados = () => {
    if (userType === 1 && userName === "Gestor") {
      return (
        <>
          <Quadrado icone="homeIc" texto="Página Inicial" className="Quadrado0" to="./" />
          <Quadrado icone="chart" texto="Financeiro" className="Quadrado1" to="./Financeiro" />
          <Quadrado icone="user-plus" texto="Cadastro de Usuários" className="Quadrado3" to="./CadastroUsuarios" />
          <Quadrado icone="file" texto="Cadastro de Escola" className="Quadrado2" to="./CadastroEscola" />
          <Quadrado icone="matricula" texto="Matrícula" className="Quadrado9" to="./Matricula" />
          <Quadrado icone="book-open" texto="Audios" className="Quadrado4" to="./CadastroCursos" />
          <Quadrado icone="users" texto="Turmas" className="Quadrado5" to="./Turma" />
          <Quadrado icone="address-book" texto="Sala de Aula" className="Quadrado6" to="./SalaDeAula" />
          <Quadrado icone="certificate" texto="Certificados" className="Quadrado7" to="./Certificados" />
          {/* <Quadrado icone="clipboard" texto="Avaliação" className="Quadrado8" to="./Avaliacao" /> */}
          <Quadrado icone="material" texto="Treinamentos" className="Quadrado10" to="./Material" />
          <Quadrado icone="material" texto="Material Extra" className="Quadrado5" to="./MaterialExtra" />
          <Quadrado icone="material" texto="Teste" className="Quadrado5" to="./Testes" />
        </>
      );
    }
    if (userType === 1) {
      return (
        <>
          <Quadrado icone="homeIc" texto="Página Inicial" className="Quadrado0" to="./" />
          <Quadrado icone="chart" texto="Financeiro" className="Quadrado1" to="./Financeiro" />
          <Quadrado icone="user-plus" texto="Cadastro de Usuários" className="Quadrado3" to="./CadastroUsuarios" />
          <Quadrado icone="file" texto="Cadastro de Escola" className="Quadrado2" to="./CadastroEscola" />
          <Quadrado icone="matricula" texto="Matrícula" className="Quadrado9" to="./Matricula" />
          <Quadrado icone="book-open" texto="Audios" className="Quadrado4" to="./CadastroCursos" />
          <Quadrado icone="users" texto="Turmas" className="Quadrado5" to="./Turma" />
          <Quadrado icone="material" texto="Treinamentos" className="Quadrado10" to="./Material" />
          <Quadrado icone="material" texto="Material Extra" className="Quadrado5" to="./MaterialExtra" />
          {/* <Quadrado icone="address-book" texto="Sala de Aula" className="Quadrado6" to="./SalaDeAula" />
          <Quadrado icone="certificate" texto="Certificados" className="Quadrado7" to="./Certificados" />
          <Quadrado icone="clipboard" texto="Avaliação" className="Quadrado8" to="./Avaliacao" /> */}
        </>
      );
    }

    else if (userType === 2 || userType === 3) {
      return (
        <>
          <Quadrado icone="homeIc" texto="Página Inicial" className="Quadrado0" to="./" />
          <Quadrado icone="chart" texto="Financeiro" className="Quadrado1" to="./Financeiro" />
          <Quadrado icone="user-plus" texto="Cadastro de Usuários" className="Quadrado3" to="./CadastroUsuarios" />
          {/* <Quadrado icone="file" texto="Cadastro de Escola" className="Quadrado2" to="./CadastroEscola" /> */}
          <Quadrado icone="matricula" texto="Matrícula" className="Quadrado9" to="./Matricula" />
          <Quadrado icone="book-open" texto="Audios" className="Quadrado4" to="./CadastroCursos" />
          <Quadrado icone="users" texto="Turmas" className="Quadrado5" to="./Turma" />
          <Quadrado icone="address-book" texto="Sala de Aula" className="Quadrado6" to="./SalaDeAula" />
          {/* <Quadrado icone="certificate" texto="Certificados" className="Quadrado7" to="./Certificados" /> */}
          {/* <Quadrado icone="clipboard" texto="Avaliação" className="Quadrado8" to="./Avaliacao" /> */}
        </>
      );

    } else if (userType === 4) {
      return (
        <>
          <Quadrado icone="homeIc" texto="Página Inicial" className="Quadrado0" to="./" />
          <Quadrado icone="users" texto="Turmas" className="Quadrado5" to="./Turma" />
          <Quadrado icone="book-open" texto="Audios" className="Quadrado4" to="./CadastroCursos" />
          <Quadrado icone="address-book" texto="Sala de Aula" className="Quadrado6" to="./SalaDeAula" />
          {/* <Quadrado icone="certificate" texto="Certificados" className="Quadrado7" to="./Certificados" /> */}
          {/* <Quadrado icone="clipboard" texto="Avaliação" className="Quadrado8" to="./Avaliacao" /> */}
        </>
      );
    } else if (userType === 5) {
      return (
        <>
          <Quadrado icone="homeIc" texto="Página Inicial" className="Quadrado0" to="./" />
          <Quadrado icone="users" texto="Turmas" className="Quadrado5" to="./Turma" />
          {/* <Quadrado icone="book-open" texto="Audios" className="Quadrado4" to="./CadastroCursos" /> */}
          {/* <Quadrado icone="chart" texto="Financeiro" className="Quadrado1" to="./Financeiro" /> */}
          <Quadrado icone="address-book" texto="Sala de Aula" className="Quadrado6" to="./SalaDeAula" />
          {/* <Quadrado icone="certificate" texto="Certificados" className="Quadrado7" to="./Certificados" />
          <Quadrado icone="clipboard" texto="Avaliação" className="Quadrado8" to="./Avaliacao" /> */}
        </>
      );
    }

    else {
      // window.location.href = '/';
      // return navigate('/');
      // return null; // Usuário não autenticado ou tipo de usuário desconhecido
      return navigate('/index/404');
    }
  };

  return (
    <div className={`container-cipex ${menuOpen ? 'menu-aberto' : ''}`}>

      <div className="coluna-70">
        <div className="linha-retangulos">
          {renderQuadrados()}
        </div>

        <div className="linha-conteudo">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Financeiro" element={<Financeiro />} />
            <Route path="/CadastroEscola" element={<CadastroEscola />} />
            <Route path="/CadastroUsuarios" element={<CadastroUsuarios />} />
            <Route path="/Matricula" element={<Matricula />} />
            <Route path="/CadastroCursos" element={<Cursos />} />
            <Route path="/Turma" element={<Turmas />} />
            <Route path="/SalaDeAula" element={<Chamada />} />
            <Route path="/Certificados" element={<Certificados />} />
            <Route path="/Avaliacao" element={<Avaliacao />} />
            <Route path='/Material' element={<Material />} />
            <Route path='/MaterialExtra' element={<MaterialExtra />} />
            <Route path='/Testes' element={<Teste />} />
            <Route path='/404' element={<Pagina404 />} />
          </Routes>
        </div>
      </div>
      <div className="menu-toggle" onClick={handleMenuToggle}>
        <div className="hamburger-icon">
          {menuOpen ? <FaTimes /> : <FaBars />}
        </div>
      </div>
      <div className="coluna-30 ">

        {/* Coluna com 30% */}
        <div className="quadrado-dividido">
          <div className="foto-perfil">
            <img src={userProfilePhotoUrl} alt="Foto do perfil" className="foto-perfil" />
            {/* Ícone de lápis para editar a foto de perfil */}

            <FaEdit className="edit-profile-icon" onClick={handleEditProfilePhoto} />

            {/*<icon icone="file" stroke="#FFF" fill='#FFF' className="edit-profile-icon" onClick={handleEditProfilePhoto} /> */}
            {/* Input de arquivo oculto */}
            <input type="file" id="fileInput" style={{ display: 'none' }} onChange={handleFileInputChange} />
          </div>
          <div className="nome-usuario">{userName}</div>
          <button className="botao-sair" onClick={handleLogout}>Sair</button>
        </div>
        <div>
          <h2 className='agenda-titulo'>Agenda</h2>
          <Agenda />
        </div>
      </div>
    </div >
  );
}

export default PaginaInicial;
